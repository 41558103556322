@import "./../../styles/variables";

.lead {

  font-size: 1.1rem !important;
  margin-bottom: .6rem;
  font-family: Arial, sans-serif;

  // text-align   : right
}

.top-lead {

  margin-top: 2rem;
}

.lead-below-image {
  text-align: center;
}

.hero-animation-img {

  text-align: center;
}


.hero-section-wrap {
  position: relative;
  background-position: center 25%;
  background-size: cover;
  height: 750px;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
  padding-top: 0;
  overflow: hidden;

  // animation          : zoom-in-zoom-out 4s ease-out infinite;
  &::before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, rgba(1, 5, 45, 0.59) 0%, rgba(255, 255, 255, 0) 69.11%), url("../../assets/Images/LandingPage/Top-Section-Background.jpg");
    transform: matrix(-1, 0, 0, 1, 0, 0);
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    content: "";
    animation: zoom-in-zoom-out 4s ease-out infinite;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.1, 1.1);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  @media #{$sm} {
    height: 315px;
    margin-top: 80px;
  }

  @media #{$md} {
    margin-top: 85px;
    height: 700px;
    padding: 3rem 0;
  }

  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    margin-top: 6rem;
  }

  @media #{$xsm} {

    height: 490px;
  }
}

.banner-section-row {
  @media #{$sm} {
    display: flex;
    flex-direction: column-reverse;
  }
}

.banner-image {
  @media #{$sm} {
    height: 50vh;
    text-align: center;
    margin-top: 4rem;
    text-align: center;

    img {
      height: 100%;
      //   display: none;
    }

  }
}

.banner-section {
  @media #{$sm} {
    text-align: center;
  }
}

.hero-section-button {
  display: flex;

  // justify-content: space-between;
  @media #{$sm} {
    justify-content: center;
  }
}

.hero-section-arabic-button {
  display: flex;
  justify-content: flex-end;

  @media #{$sm} {
    justify-content: center;
  }
}

.banner-arabic-style {
  display: flex;
  flex-direction: row-reverse;
}

.app-store-button-wrap {
  a {
    width: 38%;
    height: 10%;

    @media #{$sm} {
      width: 40%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.apple-store-wrap {
  margin-left: 5%;

  @media #{$sm} {
    margin-left: none;
  }
}

.banner-heading {
  font-size: 33px;
  font-weight: 600 !important;
  font-family: Arial, sans-serif;

  //text-align : right;
}

.banner-arabic-heading {
  text-align: right;

  @media #{$sm} {
    text-align: center;
  }

  h2 {
    font-family: Arial, sans-serif !important;
  }

  p {
    font-family: Arial, sans-serif;

  }
}


.android-store,
.apple-store-wrap {
  background: transparent;
  border: none;
  width: 40%;

}

.android-store:focus,
.apple-store-wrap:focus {

  outline: none;
}

.android-store img,
.apple-store-wrap img {

  width: 100%;
}

.banner-form {
  background: #ffffffbf;
  padding: 2rem;

  input {
    margin: 0.5rem auto;
    // border-radius: 30px;
  }

  .form-control {

    height: calc(1.5em + 0.75rem + 2px);
  }

  .formheading {
    font-size: 20px;
    text-align: center;
    margin-bottom: 1rem;
  }

  // .input-button-wrap {
  //   border       : none !important;
  //   background   : #ffffffbf;
  //   border-radius: 0;
  //   box-shadow   : 0px 4px 16px rgba(51, 51, 51, 0.1);
  //   padding      : 2rem;
  //   float        : left;



  //   @media #{$xsm} {
  //     padding: 2rem 0px !important;
  //   }

  // }

}

p.lead {
  font-weight: 400 !important;
}

.header_cta_mobile {
  display: none;

  @media #{$sm} {
    display: block;
    margin: 21px auto;
  }
}

.zohoFormDisplayOnDesktop {
  display: block;
  margin-top: 4rem;

  @media #{$sm} {
    display: none;
    margin-top: 0rem;
  }

  @media #{$md} {
    margin-top: 0rem;
  }
}

.navbar-brand {
  @media #{$xsm} {

    width: 100%;
  }

  img {
    margin: 0px auto;
    display: block;
    height: 70px;
    width: auto;
  }

}

.hero_banner_button {
  background-color: #01052d;
  border-radius: 0;
  text-transform: uppercase;
  border-color: #FFF;

  &:hover,
  &:focus {
    background: url(../../../src/assets/ButtonIcons/rozella-btn-icon.png), linear-gradient(90deg, #C72AC7 -12.5%, #7249F4 69.46%, #1A6BD8 113.25%);
    background-repeat: no-repeat;
    background-position: right;
  }
}


.banner-section {

  .banner-heading {
    font-size: 33px !important;
  }

  .lead {
    font-size: 16px !important;
  }
}

@media (max-width: 1024px) {

  .banner-section {

    .banner-heading {
      font-size: 30px !important;
    }

  }

}

@media (max-width: 767px) {

  .banner-section {

    .banner-heading {
      font-size: 20px !important;
      text-align: center;
    }

    .lead {
      text-align: center;
      font-size: 14px !important;
    }

  }
}

.video-play-icon {
  background-color: #01052D80;
  border: none;
}

.rozella-play-btn {
  font-size: 30px;
  color: #01052D;
}

.templateWidth {
  padding: 0 !important;
}

.brandingWrapper {
  display: none !important;
}

.tempHeadContBdr .frmTitle {
  text-align: center;
  color: #000;
}

.tempHeadContBdr {
  border: 0;
}

.tempHeadContBdr {
  background-color: white;
}