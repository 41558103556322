body {
  margin: 0;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #36454F;
}

code {
  font-family: Arial, sans-serif;
}

.navbar {
  background: transparent;
  padding: 20px 0;
}

.custom-nav.affix {

  background: #000 !important;
  border-bottom: 1px solid #000 !important;

}