@import "./../../styles/variables";

.about-buraq-wrap {
  padding: 0px 0px 50px;
  color: #FFFFFF;
  font-family: Arial, sans-serif;


  @media #{$sm} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .about-text-wrap {
    height: 100%;
    text-align: center;

    @media #{$sm} {
      margin-top: 1rem;
      text-align: justify;
    }

    .about-buraq-heading {
      padding: 0 0 2rem 0;
      text-align: center;
      // font-size  : 30px;
      line-height: 45px;
      font-weight: 900;
      color: #000;

      @media #{$xsm} {

        font-size: 22px;
        padding-bottom: 1rem;
      }
    }
  }

  .about-arabic-text-wrap {
    @media #{$sm} {
      text-align: right;
    }
  }

  .about-image-wrap {
    width: 80%;
    height: 100%;

    @media #{$sm} {
      text-align: center;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @media #{$sm} {
        width: 85%;
      }
    }
  }

  //! --Abrabic Style--
  .about-arabic-style {
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
  }

  .about-buraq-row {
    @media #{$sm} {
      display: flex;
      flex-direction: column;
    }
  }

  .rozella-row {
    padding: 50px 0px 0px 0px;
  }

  .rozella-row2 {
    padding: 0px 0px;
  }

  .rozella-btn2::before {
    background: url('../../assets/ButtonIcons/rozella-btn-icon2.png'), linear-gradient(90deg, #C72AC7 -12.5%, #7249F4 69.46%, #1A6BD8 113.25%);
    background-repeat: no-repeat;
    background-position: right;
  }

  .rozella-btn2 {
    width: 100%;
    height: 100px;
    font-size: 25px;
    background-color: #01052D;
    line-height: 70px;
    border: none;
  }

  .rozella-btn3 {
    background: none;
  }
}

#why-choose-us {
  background-color: #01052D;
}

.rozella-heading {
  font-size: 33px;
  font-weight: 600;
}

.rozella-heading2 {
  font-size: 25px;
  font-weight: 600;
  color: #FFFFFF;
}

p {
  text-align: justify;
}

.rozella-subheading {
  font-size: 20px;
  text-align: justify;

  @media #{$sm} {
    font-size: 18px;
    text-align: justify;
    margin: 1rem 0;
  }
}

.about-text-detail {
  font-size: 16px;
  color: #000;
}

.about-arabic-text {
  h5 {
    font-family: Arial, sans-serif;

  }

  p {
    font-family: Arial, sans-serif;

  }
}

.about-text-wrap {
  text-align: justify;
}

.rozella-content {
  padding: 50px 40px;
  font-size: 16px;
}

@media (max-width: 768px) {

  .about-buraq-wrap {
    margin: 0px;
  }

  p,
  ul {
    font-size: 14px;
  }
}

@media (max-width: 500px) {

  .rozella-content {
    padding: 50px 35px;
    text-align: center;
  }
}

.chooseImageZoom {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 0px;
  overflow: hidden;

  &::before {
    background-image: url("../../assets/Images/LandingPage/Rozella-Image2.jpg");
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    animation: zoom-in-zoom-out 4s ease-out infinite;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.1, 1.1);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}