#crmWebToEntityForm.zcwf_lblLeft {
	width: 100%;
	padding: 25px;
	margin: 0 auto;
	box-sizing: border-box;
}

#crmWebToEntityForm.zcwf_lblLeft * {
	box-sizing: border-box;
	font-weight: bold;
}

#crmWebToEntityForm {
	text-align: left;
}

#crmWebToEntityForm * {
	direction: ltr;
}

.zcwf_lblLeft .zcwf_title {
	word-wrap: break-word;
	padding: 0px 10px;
	font-weight: bold;
}

.zcwf_lblLeft .zcwf_col_fld select {
	border-radius: 4px;
	width: 100%;
	border: 1px solid #ccc !important;
	resize: vertical;
	float: left;
	height: 33px;
	padding: 1px 9px;
	font-size: 12px;
	font-family: "Arial"

}

.zcwf_lblLeft .zcwf_col_fld input[type=text],
.zcwf_lblLeft .zcwf_col_fld textarea,
.zcwf_lblLeft .zcwf_col_fld input[type=email] {
	border-radius: 4px;
	width: 100%;
	border: 1px solid #ccc !important;
	resize: vertical;
	/* border-radius: 2px; */
	float: left;
	height: 33px;
	padding: 1px 9px;
	font-size: 12px;
	font-family: "Arial"

}

.zcwf_lblLeft .zcwf_col_lab {
	width: 100%;
	word-break: break-word;
	padding: 0px 6px 0px;
	margin-right: 10px;
	margin-top: 5px;
	float: left;
	min-height: 1px;
	font-size: 12px;
	font-family: "Arial"
}

.zcwf_lblLeft .zcwf_col_fld {
	float: left;
	width: auto;
	padding: 0px 6px 0px;
	position: relative;
	margin-top: 5px;
	width: 100%;
}

.zcwf_lblLeft .zcwf_privacy {
	padding: 6px;
}

.zcwf_lblLeft .wfrm_fld_dpNn {
	display: none;
}

.dIB {
	display: inline-block;
}

.zcwf_lblLeft .zcwf_col_fld_slt {
	width: 60%;
	border: 1px solid #ccc;
	background: #fff;
	border-radius: 4px;
	font-size: 12px;
	float: left;
	resize: vertical;
}

.zcwf_lblLeft .zcwf_row:after,
.zcwf_lblLeft .zcwf_col_fld:after {
	content: '';
	display: table;
	clear: both;
}

.zcwf_lblLeft .zcwf_col_help {
	float: left;
	margin-left: 7px;
	font-size: 12px;
	max-width: 35%;
	word-break: break-word;
}

.zcwf_lblLeft .zcwf_help_icon {
	cursor: pointer;
	width: 16px;
	height: 16px;
	display: inline-block;
	background: #fff;
	border: 1px solid #ccc;
	color: #ccc;
	text-align: center;
	font-size: 11px;
	line-height: 16px;
	font-weight: bold;
	border-radius: 50%;
}

.zcwf_lblLeft .zcwf_row {
	margin: 0.5rem 0;
}

.zcwf_lblLeft .formsubmit {
	margin-right: 5px;
	cursor: pointer;
	color: #333;
	font-size: 12px;
}

.zcwf_lblLeft .zcwf_privacy_txt {
	width: 90%;
	color: rgb(0, 0, 0);
	font-size: 12px;
	font-family: Arial, sans-serif;
	display: inline-block;
	vertical-align: top;
	color: #333;
	padding-top: 2px;
	margin-left: 6px;
}

.zcwf_lblLeft .zcwf_button {
	font-size: 12px;
	color: #333;
	border: 1px solid #ccc;
	padding: 3px 9px;
	border-radius: 4px;
	cursor: pointer;
	max-width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.zcwf_lblLeft .zcwf_tooltip_over {
	position: relative;
}

.zcwf_lblLeft .zcwf_tooltip_ctn {
	position: absolute;
	background: #dedede;
	padding: 3px 6px;
	top: 3px;
	border-radius: 4px;
	word-break: break-all;
	min-width: 50px;
	max-width: 150px;
	color: #333;
}

.zcwf_lblLeft .zcwf_ckbox {
	float: left;
}

.zcwf_lblLeft .zcwf_file {
	width: 55%;
	box-sizing: border-box;
	float: left;
}

.clearB:after {
	content: '';
	display: block;
	clear: both;
}

// #formsubmit {
// 	background: transparent !important;
// 	border: 1px solid #01052d;
// 	float: left;
// 	padding: 0.5rem 2rem;
// 	font-weight: bold;
// 	font-size: 14px;
// }

@media all and (max-width: 600px) {

	.zcwf_lblLeft .zcwf_col_lab,
	.zcwf_lblLeft .zcwf_col_fld {
		width: auto;
		float: none !important;
	}

	.zcwf_lblLeft .zcwf_col_help {
		width: 40%;
	}
}